.btn-shadow {
  box-shadow: -1px 0px 21px 1px rgba(174,66,150,0.4);
  -webkit-box-shadow: -1px 0px 21px 1px rgba(174,66,150,0.4);
  -moz-box-shadow: -1px 0px 21px 1px rgba(174,66,150,0.4);
}

.form-label {
  font-weight: 400;
  margin-bottom: 0.3rem;
  color: #555c63;
}